<template>
    <div class="digitalFlopBox">
        <div class="boxContainer">
            <div class="box-item" :style="{backgroundImage: 'url(' + require('@/assets/num-bg.png') + ')' }" v-for="(item, index) in Num.numList" :key="index">
                <span class="itemNum" :class="{ isChange: Num.flag }" ref="spanRefs">0123456789</span>
            </div>
        </div>
    </div>
</template>
<script >
export default {
  name:'NumScroll',
  props:{
    Num: {
      type: Object,
      default: function () {
        return {
          numList: [0, 0, 0, 0, 0, 0],
          flag: true
        }
      }
    }
  },
  data(){
    return {
      // Num:{
      //   numList: [0, 0, 0, 0, 0, 0],
      //   flag: true
      // }
    }
  },
  watch:{
    'Num.numList':function (newVal, oldVal){
        if(Array.isArray(this.$refs.spanRefs)){
          this.$refs.spanRefs.map((item, index) => {
            const span = this.$refs.spanRefs[index];
            if (span) {
              setTimeout(() => {
                span.style.transform = `translate(-50%, -${newVal[index] * 10}%)`
              }, index*500)
            }
          })
        }
    }
  },
  methods:{
    changeNum(){
      this.Num.numList = [1, 6, 8, 9, 3, 5]
    }
  },
}
</script>

<style scoped>
.digitalFlopBox .boxContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: fit-content;
  height: fit-content;
}
.box-item {
  width: 6.25rem;
  height: 8.125rem;
  border-radius: 4%;
  color: #FFEEAD;
  text-align: center;
  background-size: 100% 100%;
  font-size: 5.625rem;
  margin-left: 0.25rem;
  position: relative;
  writing-mode: vertical-lr;
  text-orientation: upright;
  overflow: hidden;
}
.box-item .itemNum {
  font-family: Times New Roman;
  position: absolute;
  top: 1.25rem;
  left: 50%;
  transform: translateX(-50%);
  letter-spacing: 1.25rem;
  transition: transform 1s ease-in-out;
}
</style>
