<template>
    <div class="home" style="height: 100%">
      <div style="height:52.5rem" ref="bannerHeight" class="home_banner">
        <el-carousel v-if="BannerList.length" :interval="3000" arrow="hover" :height="bannerHeight">
          <el-carousel-item v-for="item in BannerList" :key="item.id">
            <img :src="item.image" alt="" :style="{height:bannerHeight,width:'120rem'}">
<!--            <el-image :src="item.image" fit="cover">-->
<!--              <div slot="placeholder" class="image-slot">-->
<!--                加载中<span class="dot">...</span>-->
<!--              </div>-->
<!--            </el-image>-->
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="flex-row items-center estimate justify-center">
        <img class="image_mercado_logo" :src="require('@/assets/mercadoLogo.png')" alt="美客多Logo"/>
        <span style="font-weight:bold;font-size: 1.5rem;min-width:6rem;margin:0 1.5rem">额度测算</span>
        <el-input  v-model="monthlyPrice" placeholder="请输入您的月平均销售额" class="monthly_price"></el-input>
        <span style="display:inline-block;font-weight:bold;font-size: 2.125rem;width: 16rem;margin:0 1.5rem">≈￥{{$utils.PriceFormat(loanLimit)}}</span>
        <el-button class="main_btn2 Apply_of"  @click="ApplyVisible=true">我要申请</el-button>
      </div>
      <div class="flex-row justify-center items-center height-700">
        <div class="flex-col justify-between items-start" style="width: 17.375rem;height: 9.75rem;min-width: 192px">
          <span style=" color: #333333;font-size: 4rem; font-weight: 700;line-height: 3.94rem;">麦嘉宝</span>
          <span style="font-size: 1.125rem">致力为卖家提供<br/>安全、专业、合规的跨境金融服务</span>
        </div>
        <div class="flex-col items-center preponderanceList" style="opacity: 0" v-for="item in preponderanceList" >
          <img
              class="image_preponderance"
              :src="item.url"
          />
          <span class="title_preponderance">{{item.title}}</span>
          <span class="content_preponderance">{{item.content}}</span>
        </div>
      </div>
      <div class="flex-col items-center loans_bg"  :style="{backgroundImage: 'url(' + require('@/assets/loans_bg.png') + ')'}">
        <span class="gold_text title">卓越的产品方案与服务</span>
        <div class="flex-row">
            <div class="flex-col ServerList" style="opacity: 0" v-for="(item, index) in ServerList" :key="index">
                <span class="gold_text ServerList_title">{{item.title}}</span>
                <div class="ServerList_content">{{item.content}}</div>
                <span class="ServerList_absolute">0{{index+1}}</span>
            </div>
        </div>
        <span style="font-size: 1.5rem;line-height: 1.47rem;font-weight: 700;margin-top: 3.63rem;margin-bottom:1.625rem;color: #d1d1d1;">已成功为卖家提供资金</span>
        <NumScroll :Num="Num"></NumScroll>
      </div>
      <div class="flex-col ">
        <div class="flex-col items-center justify-center companion">
          <span style="color: #333333;font-size: 4rem;font-weight: 700;line-height: 3.91rem;margin-bottom: 3.5625rem">战略合作伙伴</span>
          <div class="flex-col">
            <div class="flex-row div_img_bank">
              <img :src="require('@/assets/SPDBANK.png')"  style="width:13.375rem;height:3.75rem;">
              <img :src="require('@/assets/ICBC.png')" class="image_bank" style="width:18.625rem;height:3.75rem "/>
              <img :src="require('@/assets/CHINAMERCHANTSBANK.png')" class="image_bank" style="width:15.25rem;height:3.75rem ">
            </div>
            <div class="flex-row div_img_bank">
              <img :src="require('@/assets/SHANGHAIBANK.png')" class="image_bank" style="width:13rem;height:4.375rem">
              <img :src="require('@/assets/AgriculturalBank.png')" class="image_bank" style="width:18.625rem;height:3.75rem "/>
              <img :src="require('@/assets/CCB.png')" class="image_bank" style="width:15.25rem;height:4rem ">
            </div>
            <div class="flex-row div_img_bank">
              <img :src="require('@/assets/PINGANBANK.png')" class="image_bank" style="width:12.8125rem;height:4.375rem"/>
              <img :src="require('@/assets/BEIJINGBANK.png')" class="image_bank" style="width:18.625rem;height:4.125rem"/>
              <img :src="require('@/assets/BANKOFCHINA.png')" class="image_bank" style="width:15.25rem;height:4.5rem "/>
            </div>
          </div>
        </div>
        <div class="flex-col" style="background: #1F2225;flex: 1;padding: 3.75rem 22.5rem 0;">
          <div class="flex-row items-center space-x-4">
            <img
                style="width:4.25rem;height:5.125rem"
                :src="require('@/assets/logo.png')"
            />
            <img
                style="margin-left:0.75rem;width:17.8125rem;height: 3.375rem"
                :src="require('@/assets/white_MAKAFIM.png')"
            />
          </div>
          <div class="flex-row justify-between items-center">
            <div class="flex-col" style="width: 70%;border-bottom: 0.125rem solid #2F3237">
              <span style="font-size: 2.25rem;margin: 2rem 0;color: #FFFFFF">为跨境企业提供全方位、高效、安全的跨境金融服务</span>
              <el-button class="main_color main_btn2" style="width:13rem;margin-bottom: 3rem;border:0;font-size: 1.5rem;color:#161616" @click="ApplyVisible=true">我要申请</el-button>
            </div>
            <img
                style="width:7.75rem;height:7.75rem"
                :src="require('@/assets/QR_code.png')"
            />
          </div>
          <div style="font-size:0.875rem;height: 1.125rem;margin: 2rem 0;color:#FFFFFF">版权所有@麦嘉Maka<a class="aq" target="_blank" href="https://beian.miit.gov.cn">粤ICP备2023119718号-1</a></div>
        </div>
      </div>
      <el-dialog
          :visible.sync="ApplyVisible"
          width="75rem"
          :close-on-click-modal="false"
          class="details-dialog-css"
      >
        <template slot="title">
          <div class="flex-row items-center">
            <img :src="require('@/assets/logo.png')" alt="" style="width: 2rem">
            <img :src="require('@/assets/MAKAFIM.png')" alt="" style="width: 6rem;margin-left: 0.6rem">
          </div>
        </template>
        <div class="flex-row items-center" style="height: 28.4rem;margin-top: -20px">
          <div>
            <img :src="require('@/assets/apply_bg.png')" alt="申请背景图" style="width: 36.375rem">
          </div>
          <div v-if="!ApplySuccess" class="flex-col items-center justify-center" style="height: 100%;border-left: 1px solid #EFEFEF;padding: 0 4rem;flex: 1">
            <span  style="margin-bottom:2rem;color: #333333;font-size: 2rem;line-height: 1.97rem;">融资申请</span>
            <el-form ref="Apply" :rules="ApplyRules" :model="ApplyFormData" label-position="top" label-width="5rem" style="width: 100%">
              <el-form-item prop="name">
                <template slot="label">
                  <span class="label_login">姓名</span>
                </template>
                <div class="flex-row">
                  <el-input class="get_login" placeholder="请输入您的姓名" v-model="ApplyFormData.name" clearable></el-input>
                </div>
              </el-form-item>
              <el-form-item prop="mobile">
                <template slot="label">
                  <span class="label_login">手机号</span>
                </template>
                <el-input class="get_login" placeholder="请输入您的手机号" v-model="ApplyFormData.mobile" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button class="main_btn2 submit_Apply main_color" @click="ApplySubmit">立即申请</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div v-else class="flex-col items-center justify-center" style="height: 100%;border-left: 1px solid #EFEFEF;padding: 0 4rem;flex: 1">
            <img style="width: 18.75rem" :src="require('@/assets/succeed.png')" alt="申请成功">
            <span class="text_4" style="font-size: 2rem;line-height: 4rem">申请成功</span>
            <span>客服人员将第一时间联系您,请留意客服电话</span>
            <el-button class="to_login main_btn2" @click="ToLogin">去登录</el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog
          :visible.sync="LoginVisible"
          width="75rem"
          :close-on-click-modal="false"
          class="details-dialog-css"
          @close="close"
      >
        <template slot="title">
          <div class="flex-row items-center">
            <img :src="require('@/assets/logo.png')" alt="" style="width: 2rem">
            <img :src="require('@/assets/MAKAFIM.png')" alt="" style="width: 6rem;margin-left: 0.6rem">
          </div>
        </template>
        <div class="flex-row items-center" style="height: 28.4rem;margin-top: -1.25rem">
          <div>
            <img :src="require('@/assets/login-bg.png')" alt="登录背景图" style="width: 36.375rem">
          </div>
          <div class="flex-col items-center justify-center" style="height: 100%;border-left: 1px solid #EFEFEF;padding: 0 4rem;flex: 1">
            <span  style="margin-bottom:2rem;color: #333333;font-size: 2rem;line-height: 1.97rem;">创建/登入您的账号</span>
            <el-form ref="Login" :rules="LoginRules" :model="LoginFormData" label-position="top" label-width="80px" style="width: 100%">
              <el-form-item prop="mobile">
                <template slot="label">
                  <span class="label_login">手机号</span>
                </template>
                <div class="get_login">
                  <el-input  placeholder="请输入您的手机号" v-model="LoginFormData.mobile" clearable></el-input>
                </div>
              </el-form-item>
              <el-form-item prop="vcode">
                <template slot="label">
                  <span class="label_login">验证码</span>
                </template>
                <div class="flex-row get_login">
                  <el-input  placeholder="请输入验证码" v-model="LoginFormData.vcode" clearable></el-input>
                  <el-button class="main_btn2 button_login_code main_color" @click="GetCode" :disabled="codeStatus" >{{codeText}}</el-button>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button class="main_btn2 submit_login main_color" style="margin-top: 1.625rem" @click="LoginSubmit">一键登录</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-dialog>
    </div>
</template>
<script>
import {UPLOAD_IMAGE_URL} from "../../../config/index";
import NumScroll from "@/components/num/numScroll.vue";
export default {
  name: "Home.vue",
  components:{
    NumScroll
  },
  data(){
    var  validatemobile = (rule, value, callback) => {
      if(!(/^1[3456789]\d{9}$/.test(value))){
        callback(new Error('请输入正确的手机号码格式'));
      }
      callback()
    }
    return {
      //登录
      LoginVisible:false,
      //是否申请成功
      ApplySuccess:false,
      monthlyPrice:"",
      //banner图高度
      bannerHeight:'0px',
      //banner列表
      BannerList:[],
      //推荐人信息
      PData:{
        pid:'',
        makafin_source:'1',
      },
      //优势
      preponderanceList:[
          {url:require('@/assets/fast.png'),title:'快速',content:'快速完成跨境业务资金融资'},
          {url:require('@/assets/safe.png'),title:'安全',content:'确保企业交易的安全性和合规性'},
          {url:require('@/assets/specialty.png'),title:'专业团队',content:'团队具有丰富的金融和风险管理经验'},
          {url:require('@/assets/diversification.png'),title:'多样化',content:'多种金融服务，满足企业不同的金融需求'},
      ],
      //服务项目
      ServerList: [
        {title:'强大的数据分析能力', content:'通过数据分析技术，为客户提供更加精准的金融服务，同时也能为客户的未来决策提供参考'},
        {title:'综合金融服务', content:'为跨境电商卖家提供多元化、定制化的综合金融服务，能更好地满足客户的需求。帮助跨境企业获得更多融资机会，有效带动销售增长'},
        {title:'完善的风控体系', content:'拥有完善的风控体系，能够更好地控制风险，降低不良资产率，科学合理解决电商卖家的账期需求'},
        {title:'快捷、便利', content:'跨境应收账款融资服务具有快捷、便利的特点，用户能够快速地完成支付操作'}
      ],
      //申请
      ApplyVisible:false,
      //申请信息
      ApplyFormData:{
        name:'',
        mobile:'',
      },
      //申请数据校验
      ApplyRules:{
        name:[
          { required: true, message: '请输入您的姓名', trigger: 'blur'}
        ],
        mobile:[
          { required: true, message: '请输入您的手机号', trigger: 'blur'},
          {validator:validatemobile,trigger: 'blur'}
        ]
      },
      //验证码文本
      codeText:'获取验证码',
      //验证码状态
      codeStatus:false,
      //验证码定时器
      timeStop:'',
      LoginRules:{
        mobile:[
          { required: true, message: '请输入您的手机号', trigger: 'blur'},
          {validator:validatemobile,trigger: 'blur'}
        ],
        vcode:[
          { required: true, message: '请输入验证码', trigger: 'blur'}
        ]
      },
      LoginFormData:{
        mobile:'',
        vcode:'',
      },
      //资金滚动数据
      Num:{
        numList: [],
        flag: true
      },
      loansMoney:0,
    }
  },
  computed:{
    loanLimit:function (){
      let monthlyPrice=this.monthlyPrice || 0
      let price=this.$utils.calculate.multiply(monthlyPrice,1.5)
      if(price>10000000){
          price=10000000
      }else if(price<0){
          price=0
      }else{
         price=parseInt(price)
      }
      return price
    },
  },
  mounted() {
    this.elements1 = document.querySelectorAll('.preponderanceList');
    this.elements2 = document.querySelectorAll('.ServerList');
    //获取滚动器
    this.elements3 = document.querySelector('.digitalFlopBox');
    this.$nextTick(()=>{
      this.bannerHeight=this.$refs.bannerHeight.offsetHeight+'px'
    })
    this.GetBanner()
  },
  activated() {
    window.addEventListener("scroll",this.handleScroll)
    this.GetNum()
  },
  methods:{
    //获取验证码
    GetCode(){
      let query=this.$utils.CacheSessionGet('query') || this.PData
      if(!(/^1[3456789]\d{9}$/.test(this.LoginFormData.mobile))){
        this.$message.error('您的手机号格式不正确')
        return
      }
      this.codeStatus = true;
      this.$api.post(`v1/api/makafin/base_account/send/sms`, {...query,...this.LoginFormData},
          res=>{
            let num = 60;
            this.codeText = `已发送（${num} s）`;
            this.timeStop = setInterval(() => {
              num--;
              this.codeText = `已发送（${num} s）`;
              if (num === 0) {
                this.codeText = '获取验证码';
                this.codeStatus = false;
                clearInterval(this.timeStop); //清除定时器
              }
            }, 1000);
          }
      )
    },
    //登录
    LoginSubmit(){
      this.$refs['Login'].validate((valid) => {
        if (valid) {
          let query=this.$utils.CacheSessionGet('query') || this.PData
          this.$api.post('v1/api/makafin/base_account/login/vcode', {...query,...this.LoginFormData},
              res=>{
                this.$utils.cookieSet('__ERP_IDENTITY_NUMBER__', res.number);
                this.$utils.cookieSet('__ERP_IDENTITY_TOKEN__', res.token);
                this.$utils.cookieSet('__ERP_IDENTITY_TOKEN__KEY__', res.token_key);
                this.$utils.cookieSet('__ERP_USERNAME__', res.name);
                this.$utils.cookieSet('__ERP_SELLER_USERNAME__', res.seller_name);
                this.$utils.cookieSet('__ERP_SELLER_ID__', res.seller_id);
                this.$utils.CacheLocalSet('nickname', res.name)
                this.$store.commit('setNickName', res.name)
                let routeUrl= this.$router.resolve({path:'/dashboard'})
                this.LoginVisible=false
                let windowView=window.open(routeUrl.href, '_blank');
                this.$store.commit('setWindowViewClose', windowView)
              })
        }
      });
    },
    close(){
      this.$utils.CacheSessionRemove('hasLoginDialog')
      this.LoginFormData={
        mobile:'',
        vcode:'',
      }
      this.codeText = '获取验证码';
      this.codeStatus = false;
      clearInterval(this.timeStop); //清除定时器
    },
    //去登录
    ToLogin(){
      this.$api.post('v1/api/makafin/heartbeat',{},res=>{
        let routeUrl= this.$router.resolve({path:'/dashboard'})
        let windowView=window.open(routeUrl.href, '_blank');
        this.$store.commit('setWindowViewClose', windowView)
        this.ApplyVisible=false
      },err=>{
        this.ApplyVisible=false
        if(!this.$utils.CacheSessionGet('hasLoginDialog')){
          this.$utils.CacheSessionSet('hasLoginDialog',true)
          this.LoginVisible=true
        }
      })

    },
    //数字滚动函数
    ChangeNum(num){
      this.Num.numList = num.toString().split('')
    },
    //获取资金
    GetNum(){
      this.$api.post('/v1/api/makafin/index',{},res=>{
        this.loansMoney=res.makafin_money
        let sessionMoney=this.$utils.CacheSessionGet('moneyloans')
        if(sessionMoney){
          this.Num.numList =sessionMoney.toString().split('')
        }else{
          this.Num.numList = new Array(res.makafin_money.toString().split('').length).fill(0)
        }
        this.$utils.CacheSessionSet('moneyloans',res.makafin_money)
      })
    },
    //获取轮播图
    GetBanner(){
      this.$api.post('v1/api/makafin/banner/list',{},res=>{
        res.pc_banner.forEach(item=>{
          item.image=UPLOAD_IMAGE_URL+item.image+'?x-oss-process=image/resize,h_'+this.$refs.bannerHeight.offsetHeight+',m_lfit'
        })
        this.BannerList=res.pc_banner
      })
    },
    //立即申请
    ApplySubmit(){
      let query=this.$utils.CacheSessionGet('query') || this.PData;
      this.$refs['Apply'].validate((valid) => {
        if (valid) {
          this.$api.post('v1/api/makafin/base_account/registration', {...this.ApplyFormData,...query},
              res=>{
                this.ApplySuccess=true
              })
        }
      });
    },
    //滚动条事件
    handleScroll(e){
      // 获取需要监听的元素
      this.AnimateFun(this.elements1,'animate__backInRight')
      this.AnimateFun(this.elements2,'animate__zoomIn')
      const Top=this.elements3.getBoundingClientRect().top
      if(Top<window.innerHeight){
        this.ChangeNum(this.loansMoney)
      }
    },
    /**
     * 动画
     * @param el 元素
     * @param type 动画类型
     */
    AnimateFun(el,type){
      // 对于每一个需要监听的元素
      el.forEach((element,index) =>{
        // 获取元素进入视野范围的位置
        const elementTop = element.getBoundingClientRect().top;
        // 判断是否进入视野范围
        if (elementTop< window.innerHeight ) {
          // 添加需要的动画类名
          setTimeout(()=>{
            element.style.opacity=1
            element.classList.add('animate__animated',type);
          },index*500)
        }else{
            element.style.opacity=0
          element.classList.remove('animate__animated',type);
        }
      });
    },
    //动态设置banner图高度
  },
  deactivated() {
    window.removeEventListener("scroll",this.handleScroll)
  },
}
</script>
<style>
.aq{
  margin-left: 1.5rem;
  color: #FFFFFF;
  text-decoration: none;
}
.aq:hover{
  text-decoration: underline;
}
.home_banner .el-carousel__indicator.is-active button{
  width: 0.75rem;
  height: 0.75rem;
  background: #A5A5A5;
  border-radius: 50%;
  opacity: 1;
}
.home_banner .el-carousel__indicators--horizontal button{
  width: 0.75rem;
  height: 0.75rem;
  background:#E1E1E1;
  border-radius: 50%;
  opacity: 1;
}
.home_banner .el-carousel__arrow{
  width: 5.75rem;
  height:5.75rem;
}
.home_banner .el-carousel__arrow i{
  font-size: 3rem;
}
.monthly_price .el-input__inner{
  height: 3.25rem;
}
</style>
<style scoped>
.button_login_code{
  margin-left: 2rem;
  color: #000!important;
  font-size: 1.25rem;
  width: 11rem;
}
.button_login_code.el-button.is-disabled{
  background: linear-gradient(90deg, #ffbe58 0%, #ff863d 100%);
}
.submit_login{
  width: 100%;
  font-size: 1.5rem;
  color: #333333;
}
.to_login{
  margin-top: 2rem;
  font-size: 1.125rem;
  font-weight: bold;
  color:black;
  background: linear-gradient(90deg, #ffbe58 0%, #FFCA7D 100%);
}
.Apply_of{
  width:13rem;
  font-size:1.5rem;
  background-image: linear-gradient(90deg, #ffbe58 0%, #ff863d 100%);
  color:#161616
}
.loans_bg{
  background-size: 100% 100%;
  padding-bottom: 5.25rem
}
.div_img_bank{
  margin-bottom: 4.625rem;
  width: 75rem;
  justify-content: space-between;
}
.monthly_price {
  width: 27.5rem;
  min-width: 20rem;
  font-size: 1.125rem;
}
.label_login{
  font-size: 1.5rem;
  color: #333;
}
.submit_Apply{
  margin-top: 1rem;
  width: 100%;
  font-size: 1.5rem;
  color: #333333;
}
.companion{
  padding: 4.375rem 0 5.5rem;
}
.gold_text{
  color: #ffeead;
}
.gold_text.title{
  margin: 9.63rem 0 5rem 0;
  font-size: 4rem;
  font-weight: 700;
  line-height: 3.91rem;
}
.ServerList{
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  box-sizing: border-box;
  padding: 2.38rem 1.38rem 8rem;
  margin: 0 1rem;
  border-radius: 0.25rem;
  background-color: #2f3237;
  width: 17.44rem;
  height: 23.5rem;
  position: relative;
  overflow: hidden;
}
.ServerList > *:not(:first-child) {
  margin-top: 1.75rem;
}
.ServerList_title{
  font-size: 1.5rem;
  line-height: 1.47rem;
  font-weight: 700;
  color: #ffeead;
}
.ServerList_content{
  font-size: 1.13rem;
  line-height: 2rem;
  color: #A8A8A8;
}
.ServerList_absolute{
  position: absolute;
  right: 0;
  bottom: -3.625rem;
  font-size: 8.13rem;
  color: #ffffff;
  opacity: 0.06;
  margin-right: 1.38rem;
}
.preponderanceList{
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  min-width: 220px;
  flex: 1 1 22.5rem;
  color: #666666;
  padding: 3.25rem 0 2.25rem;
  margin:0 0.4rem;
  background-image: conic-gradient(from 90deg at 50% 50%, #f2f2f2 0deg, #ffffff 170.6deg, #f2f2f2 359.6deg);
  border-radius: 0.88rem;
  box-shadow: 0.25rem 0 0.63rem 0.13rem #d9d9d9 inset;
}
.title_preponderance{
  font-size: 1.5rem;
  line-height: 1.47rem;
  font-weight: 700;
  color: #333333;
  margin-top: 2rem;
}
.content_preponderance{
  margin-top: 1.13rem;
  font-size: 1.125rem;
  line-height: 1.09rem;
  color: #666666;
}
.image_preponderance{
  width: 8rem;
  height: 8rem;
}
.height-700{
  overflow:hidden;
  height: 19.75rem;
  padding: 11.5rem 5rem 12.9rem 4.625rem;
}
.image_mercado_logo{
  width: 12.625rem;
  height: 3.5rem;
}
.estimate{
  padding:3.25rem 0;
  height: 3.25rem;
  background-color: #F0F6F9;
}
</style>